export const imports = {
  'docz/Introduction.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-introduction" */ 'docz/Introduction.mdx'
    ),
  'docz/demos/basic/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-demos-basic-index" */ 'docz/demos/basic/index.mdx'
    ),
  'docz/demos/createReplacementNode/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-demos-create-replacement-node-index" */ 'docz/demos/createReplacementNode/index.mdx'
    ),
}
